import React, { lazy } from 'react';
import {
  SettingOutlined,
  RocketOutlined,
  UsergroupDeleteOutlined,
  OrderedListOutlined,
  FunnelPlotOutlined,
  DollarOutlined,
  TeamOutlined
} from '@ant-design/icons';

/**
 * 路由懒加载
 * @param {String} filename 文件路径
 */
const lazyRouter = (filename: string) => {
  return lazy(() => import(`../pages/${filename}`))
}
export const RouteMap = [
  { path: '/', name: 'Home', title: '首页', component: lazyRouter('Home/Index'), isMenu: false},
  { path: '/product', name: 'product', title: '产品管理', icon: <RocketOutlined />, component: lazyRouter('product/Index'), isMenu: true},
  { path: '/order', name: 'apply', title: '订单管理', icon: <OrderedListOutlined />, component: lazyRouter('order/Index'), isMenu: true},
  { path: '/agent', name: 'agent', title: '经纪人管理', icon: <UsergroupDeleteOutlined />, component: lazyRouter('agent/Index'), isMenu: true},
  { path: '/cash', name: 'commission', title: '佣金管理', icon: <DollarOutlined />,isMenu: true,
    children: [
      { path: '/cash', name: 'commission', title: '提现列表', component: lazyRouter('cash/Index'), isMenu: true},
      { path: '/Award', name: 'reward', title: '奖励明细',  component: lazyRouter('cash/Award'), isMenu: true},
    ]
  },
  // { path: '/group', name: 'group', title: '商户管理', icon: <TeamOutlined />, component: lazyRouter('group/Index'), isMenu: true},
  { path: '/invite', name: 'invitation', title: '邀请有礼', icon: <TeamOutlined />, component: lazyRouter('invite/Index'), isMenu: true},
  { path: '', name: 'setting', title: '系统配置', icon: <SettingOutlined />, isMenu: true,
    children: [
      { path: '/banner', name: 'slide', title: '图片配置', component: lazyRouter('banner/Index'), isMenu: true},
      { path: '/configuration', name: 'system', title: '内容配置',  component: lazyRouter('configuration/Index'), isMenu: true},
      { path: '/Allocation', name: 'allocation', title: '其他配置',  component: lazyRouter('configuration/Allocation'), isMenu: true},
      // { path: '/MassTexting', name: 'mass', title: '群发消息',  component: lazyRouter('configuration/MassTexting'), isMenu: true},
      { path: '/Friends', name: 'material', title: '朋友圈素材',  component: lazyRouter('configuration/Friends'), isMenu: true},
    ]
  },
  
  { path: '', name: 'authority', title: '权限管理', icon: <FunnelPlotOutlined />, isMenu: true,
    children: [
      {path: '/account', name: 'admin', title: '账号管理', component: lazyRouter('authority/Account'), isMenu: true},
      {path: '/role', name: 'role', title: '角色管理', component: lazyRouter('authority/Role'), isMenu: true}
    ]
  },
]

let routeList: any[] = []
let neatenRouteMap = (list: any[]) => {
  list.forEach((item: any) => {
    if (item.children && item.children.length) {
      neatenRouteMap(item.children)
    } else {
      routeList.push(item)
    }
  })
}
neatenRouteMap(RouteMap)

export const RouteList = routeList
