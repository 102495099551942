import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { RouteMap } from '../../route/Index'
import PrivateRoute from '../../components/privateRoute/Index'
import { Layout, Menu, Dropdown, Avatar ,Modal, Form, Input,message} from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './Layout.css';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';
import Tags from './Tags'
import avatar from '../../images/logo.png'
import { useStore } from '../../store/context'
import { IS_LOGIN, ADD_TAG, CLOSE_ALL, SET_TOKEN,USERNAME } from '../../store/actionTypes'
import { logout,passwordModify } from '../../fetch/api'
import { relative } from 'path';

const { Header, Sider, Content } = Layout;
const { SubMenu } = Menu;

const Layout_: React.FC<any> = (props: any) => {
  const [store, dispatch] = useStore()
  const history = useHistory()
  const [menuList, setMenuList] = useState<any[]>([])
  const clickMenu = (param: any) => {
    switch (param.key) {
      case 'logout':
        logout().then((_res: any) => {
          loginOut()
        }).catch((_err: any) => {
          loginOut()
        })
        break;
      case 'password':
        showBox(null)
        break;  
    }
  }

  /**修改密码start */
  const [passwordForm] = Form.useForm()
  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 },
  }
  const [box, setBox] = useState(false) // 弹窗状态
  const [boxTitle, setBoxTitle] = useState('新增') // 弹窗title
  const showBox = (id: number | null) => { // 打开弹窗新增/编辑
    setBox(true)
    passwordForm.resetFields()
    setBoxTitle('修改密码')
  }
  const handleOk = () => { // 确定新增/编辑
    passwordForm.validateFields().then((values: any) => {
      passwordModify(values).then((res: any) => {
        if (res.code === 201) {
          message.success(res.message)
          setBox(false)
        } else {
          message.error(res.message)
          setBox(false)
        }
      }).catch((_err: any) => { })
    
    }).catch((_err: any) => {})
  }

  const password = (
    <Modal
      title={`${boxTitle}账号`}
      width={800}
      visible={box}
      onOk={handleOk}
      onCancel={() => setBox(false)}
      destroyOnClose={true}
    >
      <Form name="searchForm" form={passwordForm} {...layout}>
        <Form.Item name="password" label="设置密码"
          rules={[
            {required:true,message:'请输入密码'}
          ]}
        >
        <Input.Password placeholder="请输入密码" />
        </Form.Item>

        <Form.Item name="password_confirmation" label="确认密码"
           rules={[
            {required:true,message:'请确认密码'}
          ]}
        >
          <Input.Password placeholder="请确认密码" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    />

        </Form.Item>
          
      </Form>
    </Modal>
  )
  /**修改密码end */

  useEffect(() => {
    let arr: any[] = []
    RouteMap.forEach((item: any) => {
      if (item.children && item.children.length > 0) {
        let children_: any[] = []
        item.children.forEach((it: any) => {
          if (store.menuPermissions.includes(it.name) || store.isRoot === 1) {
            children_.push(it)
          }
        })
        Object.assign(item, {children_: children_})
        arr.push(item)
      } else {
        if (store.menuPermissions.includes(item.name) || store.isRoot === 1) {
          arr.push(item)
        }
      }
    })
    setMenuList(arr)
  }, [store])
  const loginOut = () => {
    dispatch({type: SET_TOKEN, value: ''})
    dispatch({type: CLOSE_ALL})
    dispatch({type: IS_LOGIN, value: false})
    dispatch({type: USERNAME, value: ''})
    props.history.push('/login')
  }
  const menu = (
    <Menu onClick={clickMenu} className="tx_c">
      <Menu.Item key="password">修改密码</Menu.Item> 
      <Menu.Item key="logout">退出登录</Menu.Item>
    </Menu>
  )
  const [collapsed, setCollapsed] = useState(false)
  
  const toPath = (item: any) => {
    let names = store.tags.map((item: { name: any }) => item.name)
    if (!names.includes(item.name)) {
      dispatch({type: ADD_TAG, value: item})
    }
    history.push(item.path)
  }

  return (
    <Layout style={{height: '100vh'}}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className={`logo ${collapsed ? 'small' : ''}`}>款小弟管理系统</div>
        <Menu className="menu_box scrollbar" theme="dark" mode="inline" defaultSelectedKeys={[history.location.pathname]}>
          {
            menuList.map((item: any) => (
              item.children_ ? 
              (
                item.children_.length > 0 &&
                <SubMenu key={item.name} icon={item.icon} title={item.title}>
                  {
                    item.children_.map((it: any) => (
                      <Menu.Item key={it.name} onClick={() => history.push(it.path)}>
                        {it.title}
                      </Menu.Item>
                    ))
                  }
                </SubMenu>
              ) :
              item.isMenu && 
              <Menu.Item key={item.name} icon={item.icon} onClick={() => history.push(item.path)}>
                {item.title}
              </Menu.Item>
            ))
          }
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="bgf flex_just_betw flex_cen" style={{ padding: '0 15px' }}>
          <div className="flex_just_betw flex_cen">
            {
              collapsed ? 
              <MenuUnfoldOutlined onClick={() =>setCollapsed(!collapsed)} style={{fontSize: 20}} /> :
              <MenuFoldOutlined onClick={() =>setCollapsed(!collapsed)} style={{fontSize: 20}} />
            }
            {/* <div className="ml20"><p>首页</p></div> */}
          </div>
          <div style={{position:'absolute',right:'100px'}}>欢迎您,{store.userName}</div>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <Avatar size={50} src={avatar} />
          </Dropdown>
        </Header>
        <Tags></Tags>
        <Content
          className="bgf"
          style={{
            margin: '15px',
            padding: 10,
            minHeight: 280,
          }}
        >
          <PrivateRoute />
        </Content>

        {password}
      </Layout>
    </Layout>
  )
}

export default Layout_