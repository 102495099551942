import { fetch } from './axios'

export const login: any = (data: any) => { // 登录
  return fetch(`auth/login`, data, `post`)
}
export const logout: any = (data: any) => { // 登出
  return fetch(`auth/logout`, data, 'POST')
}
export const passwordModify: any = (data: any) => { // 修改密码
  return fetch(`auth/password`, data, 'PUT')
}
export const upload: any = (data: any) => { // 上传图片
  return fetch(`image/upload`, data, 'post', true)
}
export const province: any = (data: any) => { // 获取省份
  return fetch(`code?type=province`, data)
}
export const city: any = (data: any) => { // 获取城市
  return fetch(`code?type=city`, data)
}
export const queryProduct: any = (data: any) => { // 查询产品列表/单个产品
  return fetch(`product`, data)
}
export const addProduct: any = (data: any) => { // 添加产品
  return fetch(`product`, data, `post`)
}
export const putProduct: any = (data: any) => { // 保存修改产品
  return fetch(`product/${data.id}`, data, `PUT`)
}
export const productDetail: any = (data: any) => { // 查询产品详情
  return fetch(`product/${data.id}`, data)
}
export const deleteProduct: any = (data: any) => { // 删除产品
  return fetch(`product/${data.id}`, data, `DELETE`)
}
export const queryAgent: any = (data: any) => { // 用户列表
  return fetch(`agent`, data)
}
export const queryOrder: any = (data: any) => { // 订单列表
  return fetch(`apply`, data)
}
export const finishOrder: any = (data: any) => { // 结算订单
  return fetch(`apply/${data.id}`, data, `PUT`)
}
export const failedOrder: any = (data: any) => { // 结算订单不通过
  return fetch(`apply/failed/${data.id}`, data, `POST`)
}

export const queryCash: any = (data: any) => { // 佣金列表
  return fetch(`commission`, data)
}
export const finishCash: any = (data: any) => { // 佣金结算
  return fetch(`commission/${data.id}`, data, `PUT`)
}
export const failedCash: any = (data: any) => { // 佣金结算失败
  return fetch(`commission/failed/${data.id}`, data, `PUT`)
}

export const queryBanner: any = (data: any) => { // Banner列表
  return fetch(`slide`, data)
}
export const BannerDetail: any = (data: any) => { // 查询产品列表/单个产品
  return fetch(`slide/${data.id}`, data)
}
export const addBanner: any = (data: any) => { // 创建海报
  return fetch(`slide`, data, 'post')
}
export const putBanner: any = (data: any) => { // 保存修改产品
  return fetch(`slide/${data.id}`, data, `PUT`)
}
export const delBanner: any = (data: any) => { // 删除海报
  return fetch(`slide/${data.id}`, data, 'DELETE')
}

export const getRole: any = (data: any) => { // 获取角色列表
  return fetch(`role`, data, 'get')
}
export const getRoleDetail: any = (data: any) => { // 获取角色详情
  return fetch(`role/${data.id}`, data, 'get')
}
export const addRole: any = (data: any) => { // 添加角色
  return fetch(`role`, data, 'post')
}
export const editRole: any = (data: any) => { // 编辑角色
  return fetch(`role/${data.id}`, data, 'put')
}
export const deleteRole: any = (data: any) => { // 删除角色
  return fetch(`role/${data.id}`, data, 'DELETE')
}
export const getPermission: any = (data: any) => { // 获取权限列表
  return fetch(`permission`, data, 'get')
}
export const getAdmin: any = (data: any) => { // 获取账号列表
  return fetch(`admin`, data, 'get')
}
export const addAdmin: any = (data: any) => { // 添加账号
  return fetch(`admin`, data, 'post')
}
export const getAdminDetail: any = (data: any) => { // 获取账号详情
  return fetch(`admin/${data.id}`, data, 'get')
}
export const editAdmin: any = (data: any) => { // 编辑账号
  return fetch(`admin/${data.id}`, data, 'put')
}
export const deleteAdmin: any = (data: any) => { // 删除账号
  return fetch(`admin/${data.id}`, data, 'DELETE')
}
export const passwordReset: any = (data: any) => { // 重置密码
  return fetch(`admin/password_reset/${data.id}`, data, 'put')
}
export const status: any = (data: any) => { // 冻结/解冻管理员
  return fetch(`admin/status/${data.id}`, data, 'put')
}






export const template: any = (data: any) => { // 获取所有名片模板
  return fetch(`template`, data)
}
export const addCompany: any = (data: any) => { // 添加公司
  return fetch(`company`, data, `post`)
}
export const queryCompany: any = (data: any) => { // 查询公司列表/单个公司
  return fetch(`company`, data)
}
export const getCompanyArr: any = (data: any) => { // 查询公司列表
  return fetch(`company/list`, data)
}
export const putCompany: any = (data: any) => { // 保存修改公司
  return fetch(`company/${data.id}`, data, `PUT`)
}
export const deleteCompany: any = (data: any) => { // 删除公司
  return fetch(`company/${data.id}`, data, `DELETE`)
}
export const sales: any = (data: any) => { // 查询销售列表
  return fetch(`saler`, data)
}
export const salesDeyail: any = (data: any) => { // 查询销售详情
  return fetch(`saler/${data.id}`, data)
}
export const addUsers: any = (data: any) => { // 添加销售
  return fetch(`saler`, data, `post`)
}
export const editUsers: any = (data: any) => { // 修改销售
  return fetch(`saler/${data.id}`, data, `put`)
}
export const statusSaler: any = (data: any) => { // 修改销售状态
  return fetch(`status/saler`, data, `post`)
}
export const deleteSaler: any = (data: any) => { // 删除销售
  return fetch(`saler/${data.id}`, data, `DELETE`)
}
export const importSaler: any = (data: any) => { // 销售批量导入
  return fetch(`import/saler`, data, `post`, true)
}
export const exportSaler: any = (data: any) => { // 导出表示示例
  return fetch(`export/saler`, data, `get`)
}
export const recommendsProduct: any = (data: any) => { // 查询推荐列表产品列表
  return fetch(`recommends/product`, data)
}
export const recommends: any = (data: any) => { // 查询推荐列表
  return fetch(`recommends`, data)
}
export const cards: any = (data: any) => { // 查询名片列表
  return fetch(`cards`, data)
}
export const editCards: any = (data: any) => { // 更改名片状态
  return fetch(`cards`, data, `post`)
}
export const config: any = (data: any) => { // 配置管理
  return fetch(`system`, data, `POST`)
}
export const showConfig: any = (data: any) => { // 配置管理回显
  return fetch(`system`, data)
}
export const addTemplate: any = (data: any) => { // 新建模板
  return fetch(`template`, data, 'post')
}
export const editTemplate: any = (data: any) => { // 编辑模板
  return fetch(`template/${data.id}`, data, 'put')
}
export const delTemplate: any = (data: any) => { // 删除模板
  return fetch(`template/${data.id}`, data, 'DELETE')
}
export const getTemplate: any = (data: any) => { // 获取所有模板
  return fetch(`template`, data)
}
export const getOneTemplate: any = (data: any) => { // 获取单个模板
  return fetch(`template/${data.id}`, data)
}
export const getComment: any = (data: any) => { // 评价管理
  return fetch(`comment`, data)
}
export const delComment: any = (data: any) => { // 删除评论
  return fetch(`comment/${data.id}`, data, 'DELETE')
}
export const queryGroup: any = (data: any) => { // 团队列表
  return fetch(`group`, data, 'GET')
}
export const updateGroup: any = (data: any) => { // 团队列表
  return fetch(`group/${data.group_id}`, data, 'PUT')
}

// v1.3
export const specified: any = (data: any) => { // 设为BD
  return fetch(`specified/${data.id}`, data, 'PUT')
}
export const bdlist: any = (data: any) => { // BD列表
  return fetch(`bdlist`, data, 'GET')
}
export const relieveBd: any = (data: any) => { // 解除BD
  return fetch(`relieve/${data.id}`, data, 'PUT')
}
export const getReward: any = (data: any) => { // 奖励明细
  return fetch(`reward`, data, 'GET')
}
export const updateone: any = (data: any) => { // 修改单个配置
  return fetch(`updateone`, data, 'POST')
}
export const applyList: any = (data: any) => { // 订单明细
  return fetch(`commission/apply/list`, data, 'GET')
}
export const cashExport: any = (data: any) => { // 提现列表导出
  return fetch(`cash/export`, data, 'GET')
}
export const rewardExport: any = (data: any) => { // 奖励明细导出
  return fetch(`reward/export`, data, 'GET')
}
export const applyProducts: any = (data: any) => { // 订单所有产品列表
  return fetch(`apply/products`, data, 'GET')
}
export const getGroupProduct: any = (data: any) => { // 团队获取配置产品1.3.1
  return fetch(`group/show_product/${data.group_id}`,{}, 'GET')
}
export const saveGroupProduct: any = (data: any) => { // 保存配置产品1.3.1
  return fetch(`group/sys_product/${data.group_id}`, data, 'POST')
}
export const otherConfig: any = (data: any) => { // 修改其他配置
  return fetch(`config/other`, data, 'POST')
}
export const applyExport: any = (data: any) => { // 订单列表导出
  return fetch(`apply/export`, data, 'GET')
}
export const massMessage: any = (data: any) => { // 群发模板消息v1.3.2
  return fetch(`mass/message`, data, 'POST')
}
export const adminMaterial: any = (data: any) => { // 素材列表
  return fetch(`material`, data, 'GET')
}
export const addMaterial: any = (data: any) => { // 添加素材
  return fetch(`material`, data, 'POST')
}
export const oneMaterial: any = (data: any) => { // 单个素材
  return fetch(`material/${data.id}`, data, 'GET')
}
export const editMaterial: any = (data: any) => { // 修改素材
  return fetch(`material/${data.id}`, data, 'PUT')
}
export const delMaterial: any = (data: any) => { // 删除素材
  return fetch(`material/${data.id}`, data, 'DELETE')
}
export const classification: any = (data: any) => { // 素材分类列表
  return fetch(`classification`, data, 'GET')
}
export const addClassification: any = (data: any) => { // 添加素材分类
  return fetch(`classification`, data, 'POST')
}
export const editClassification: any = (data: any) => { // 修改素材分类
  return fetch(`classification/${data.id}`, data, 'PUT')
}
export const getOneClassification: any = (data: any) => { // 查询单条分类
  return fetch(`classification/${data.id}`, data, 'GET')
}
export const delClassification: any = (data: any) => { // 删除分类
  return fetch(`classification/${data.id}`, data, 'DELETE')
}
export const labels: any = (data: any) => { // 获取进度节点 资产项
  return fetch(`labels`, data, 'GET')
}
export const addLabels: any = (data: any) => { // 添加资产项或进度节点
  return fetch(`labels/add`, data, 'POST')
}
export const delLabels: any = (data: any) => { // 删除资产项或进度节点
  return fetch(`labels/del/${data.id}`, data, 'DELETE')
}
export const invitation: any = (data: any) => { // 邀请列表
  return fetch(`invitation`, data, 'GET')
}
export const productCategory: any = (data: any) => { // 产品类别
  return fetch(`product/category`, data, 'GET')
}
export const productChannels: any = (data: any) => { // 调用接口渠道
  return fetch(`product/channels`, data, 'GET')
}
export const slideCategory: any = (data: any) => { // 获取图片类别
  return fetch(`slide/category`, data, 'GET')
}